/* eslint no-restricted-properties: 0 */
import { environments } from '@extensiv-app/utility'
import { registerApplication, start } from "single-spa"

import { hubApplicationRegistry } from '@core/constants'

// Ignore this, but don't remove. We need a reference to the image in order
// for webpack to bundle it into our build, even though we dont want to reference
// it programatically.
import './assets/img/extensiv_spinner.gif'

Object.entries(hubApplicationRegistry).map(([ name, { app, rootUrl, manifest = 'manifest.json', routes } ]) => registerApplication<any>({
  name,
  app: async () => {
    // First, check if we have any import map overrides from single-spa inspector
    if (environments.STAGE !== 'PROD') {
      const appImportOverride = window.localStorage.getItem(`import-map-override:${app}`)

      if (appImportOverride) return System.import(appImportOverride)
    }

    if (!rootUrl) return System.import(app)

    let appManifestResponse
    try {
      // Make sure the manifest request isn't cached
      appManifestResponse = await fetch(
        `${rootUrl}/${manifest}?rqid=${Math.random()}`,
        { cache: 'no-cache' }
      ).then(r => r.json())
    } catch (e) {
      console.error(`Failed to load app manifest for ${app}:`, e)
    }

    if (appManifestResponse?.appSrc) {
      return System.import(`${rootUrl}/${appManifestResponse.appSrc}`)
    }
      // Try loading the old way
      return System.import(app)

  },
  activeWhen: routes
}))

start({ urlRerouteOnly: true })
