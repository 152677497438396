import { HubProduct } from '@core/types/Application'

export const colors = {
	[ HubProduct.THREEPL_WAREHOUSE_MANAGER ]: '#415364',
	[ HubProduct.SMARTSCAN ]: '#ff5100',
	[ HubProduct.MANAGEMENT_CONSOLE ]: '#701a4a',
	[ HubProduct.SIGNUP ]: '#21b3d6',
	[ HubProduct.WAREHOUSE_MANAGER_MOBILE ]: '#ff4700',
	[ HubProduct.BILLING ]: '#21c6d6',
	[ HubProduct.NETWORK_MANAGER ]: '#bf21cc',
	[ HubProduct.HUB ]: '#ED40A9',
	[ HubProduct.ORDER_MANAGER ]: '#ff9e19',
	[ HubProduct.WAREHOUSE_MANAGER_CLASSIC_ADMIN ]: '#ed40a9',
	[ HubProduct.WAREHOUSE_MANAGER_UNLIMITED_ADMIN ]: '#ff3976',
	[ HubProduct.WAREHOUSE_MANAGER_CUSTOMER_PORTAL ]: '#fc0088',
	[ HubProduct.CONNECTION_MANAGER ]: '#ff4700',
	[ HubProduct.WAREHOUSE_MANAGER_ACCOUNT_MANAGER ]: '#ff4700',
	[ HubProduct.INTEGRATION_MANAGER ]: '#008895',
	[ HubProduct.ADMIN ]: '#d6006d',
	[ HubProduct.FULFILLMENT_MARKETPLACE ]: '#415364',
	[ HubProduct.ACCOUNTING_INTEGRATION ]: '#2ca01c',
	[ HubProduct.ANALYTICS ]: '#7D7D7D'
}
