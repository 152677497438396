import { HubProduct } from '@core/types/Application'

export const apiFlags = {
	[ HubProduct.THREEPL_WAREHOUSE_MANAGER ]: 'can3pl',
	[ HubProduct.SMARTSCAN ]: 'canSmartScan',
	[ HubProduct.MANAGEMENT_CONSOLE ]: 'canManagementConsole',
	[ HubProduct.SIGNUP ]: 'canSignUp',
	[ HubProduct.BILLING ]: 'canBilling',
	[ HubProduct.NETWORK_MANAGER ]: 'canNetworkManager',
	[ HubProduct.HUB ]: 'core',
	[ HubProduct.ORDER_MANAGER ]: 'canOrderManager',
	[ HubProduct.WAREHOUSE_MANAGER_CLASSIC_ADMIN ]: 'canWarehouseManagerClassicAdmin',
	[ HubProduct.WAREHOUSE_MANAGER_UNLIMITED_ADMIN ]: 'canWarehouseManagerUnlimitedAdmin',
	[ HubProduct.WAREHOUSE_MANAGER_CUSTOMER_PORTAL ]: 'canWarehouseManagerCustomerPortal',
	[ HubProduct.WAREHOUSE_MANAGER_MOBILE ]: 'canWarehouseManagerMobile',
	[ HubProduct.WAREHOUSE_MANAGER_ACCOUNT_MANAGER ]: 'canWarehouseManagerAccountManager',
	[ HubProduct.INTEGRATION_MANAGER ]: 'canIntegrationManager',
	[ HubProduct.CONNECTION_MANAGER ]: 'canWarehouseManagerIntegrations',
	[ HubProduct.ACCOUNTING_INTEGRATION ]: 'canAccountingIntegration',
	[ HubProduct.ADMIN ]: 'canAdmin',
	[ HubProduct.FULFILLMENT_MARKETPLACE ]: 'canFM',
	[ HubProduct.ANALYTICS ]: 'canBusinessAnalytics'
}
