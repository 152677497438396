import { Activity } from 'single-spa'

import { HubProduct } from '@core/types/Application'

import { routes } from "./appRoutes"

// function showWhenAnyOf(routes: string[]) {
//   return function (location: Location) {
//     return routes.some((route) => location.pathname === route)
//   }
// }

function showWhenPrefix(routes: string[]) {
  return function (location: Location) {
    return routes.some((route) => location.pathname.startsWith(route))
  }
}

// function showExcept(routes: string[]) {
//   return function (location: Location) {
//     return routes.every((route) => location.pathname !== route)
//   }
// }

// function showExceptWhenPrefix(routes: string[]) {
//   return function (location: Location) {
//     return routes.some((route) => !location.pathname.startsWith(route))
//   }
// }

export const hubApplicationRegistry: Record<string, { app: string, rootUrl?: string, manifest?: string, routes: Activity }> = {
  '@extensiv-app/global': {
    app: '@extensiv-app/global',
    rootUrl: process.env.MF_ROOT,
    routes: [ '/' ]
  },
  '@extensiv-app/hub': {
    app: '@extensiv-app/hub',
    rootUrl: process.env.MF_ADMIN,
    routes: showWhenPrefix([ routes[HubProduct.HUB] ])
  },
  '@extensiv-app/billing-master': {
    app: '@extensiv-app/billing-master',
    rootUrl: process.env.MF_BILLING,
    routes: showWhenPrefix([ routes[HubProduct.BILLING] ])
  },
  '@extensiv-app/network-manager': {
    app: '@extensiv-app/network-manager',
    rootUrl: process.env.MF_NETWORK,
    routes: showWhenPrefix([ routes[HubProduct.NETWORK_MANAGER] ])
  },
  '@extensiv-app/fulfillment-marketplace': {
    app: '@extensiv-app/fulfillment-marketplace',
    rootUrl: process.env.MF_FULFILLMENT,
    routes: showWhenPrefix([ routes[HubProduct.FULFILLMENT_MARKETPLACE] ])
  },
  '@extensiv-app/platform-data-import': {
    app: '@extensiv-app/platform-data-import',
    rootUrl: process.env.MF_PLATFORM_DATA_IMPORT,
    routes: showWhenPrefix([
      routes[HubProduct.THREEPL_WAREHOUSE_MANAGER],
      routes[HubProduct.ORDER_MANAGER]
    ])
  },
  '@extensiv-app/business-analytics': {
    app: '@extensiv-app/business-analytics',
    rootUrl: process.env.MF_BUSINESS_ANALYTICS,
    routes: showWhenPrefix([ routes[HubProduct.ANALYTICS] ])
  },
  '@extensiv-app/wrappers': {
    app: '@extensiv-app/wrappers',
    rootUrl: process.env.MF_WRAPPERS,
    routes: showWhenPrefix([
      routes[HubProduct.WAREHOUSE_MANAGER_CLASSIC_ADMIN],
      routes[HubProduct.WAREHOUSE_MANAGER_UNLIMITED_ADMIN],
      routes[HubProduct.WAREHOUSE_MANAGER_CUSTOMER_PORTAL],
      routes[HubProduct.CONNECTION_MANAGER],
      routes[HubProduct.WAREHOUSE_MANAGER_MOBILE],
      routes[HubProduct.WAREHOUSE_MANAGER_ACCOUNT_MANAGER],
      routes[HubProduct.MANAGEMENT_CONSOLE],
      routes[HubProduct.INTEGRATION_MANAGER],
      routes[HubProduct.THREEPL_WAREHOUSE_MANAGER],
      routes[HubProduct.SMARTSCAN],
      routes[HubProduct.SIGNUP],
      routes[HubProduct.ORDER_MANAGER],
      routes[HubProduct.ACCOUNTING_INTEGRATION]
    ])
  },
  '@extensiv-app/admin': {
    app: '@extensiv-app/admin',
    rootUrl: process.env.MF_ADMIN_APP,
    routes: showWhenPrefix([ routes[HubProduct.ADMIN] ])
  },
}
