export type HubApplicationRegistry = Record<HubProduct, IHubApplication>

export interface IHubApplication {
  key: string
  title: string
  subTitle?: string
  isBeta?: boolean
  supportsMultiInstance?: boolean
  isNew? : boolean
  rootUrl: string
  apiFlag: string
  themeColor: string
  logo: any
}

export enum HubProduct {
  BILLING = 'billing',
  WAREHOUSE_MANAGER_CLASSIC_ADMIN = 'top-shelf-classic-admin',
  WAREHOUSE_MANAGER_UNLIMITED_ADMIN = 'top-shelf-unlimited-admin',
  WAREHOUSE_MANAGER_CUSTOMER_PORTAL = 'top-shelf-customer-portal',
  WAREHOUSE_MANAGER_MOBILE = 'top-shelf-mobile',
  WAREHOUSE_MANAGER_ACCOUNT_MANAGER = 'warehouse-manager-account-manager',
  CONNECTION_MANAGER = 'warehouse-manager-integrations',
  INTEGRATION_MANAGER = 'cart-rover',
  NETWORK_MANAGER = '4pl-network',
  THREEPL_WAREHOUSE_MANAGER = 'wms',
  SMARTSCAN = 'smartscan',
  MANAGEMENT_CONSOLE = 'wms-management-console',
  SIGNUP = 'wms-signup',
  ORDER_MANAGER = 'skubana',
  HUB = 'core-admin',
  ADMIN = 'admin',
  FULFILLMENT_MARKETPLACE = 'fulfillment-marketplace',
  ACCOUNTING_INTEGRATION = 'accounting-integration',
  ANALYTICS = 'business-analytics'
}
