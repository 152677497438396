import { HubProduct } from '@core/types/Application'

export const routes = {
	[ HubProduct.THREEPL_WAREHOUSE_MANAGER ]: '/3pl-warehouse-manager',
	[ HubProduct.SMARTSCAN ]: '/smartscan',
	[ HubProduct.MANAGEMENT_CONSOLE ]: '/management-console',
	[ HubProduct.SIGNUP ]: '/sign-up',
	[ HubProduct.BILLING ]: '/billing',
	[ HubProduct.NETWORK_MANAGER ]: '/network-manager',
	[ HubProduct.HUB ]: '/hub',
	[ HubProduct.ORDER_MANAGER ]: '/order-manager',
	[ HubProduct.WAREHOUSE_MANAGER_CLASSIC_ADMIN ]: '/warehouse-manager-classic-admin',
	[ HubProduct.WAREHOUSE_MANAGER_UNLIMITED_ADMIN ]: '/warehouse-manager-admin',
	[ HubProduct.WAREHOUSE_MANAGER_CUSTOMER_PORTAL ]: '/warehouse-manager-customer-portal',
	[ HubProduct.WAREHOUSE_MANAGER_MOBILE ]: '/warehouse-manager-mobile',
	[ HubProduct.INTEGRATION_MANAGER ]: '/integration-manager',
	[ HubProduct.CONNECTION_MANAGER ]: '/warehouse-manager-integrations',
	[ HubProduct.WAREHOUSE_MANAGER_ACCOUNT_MANAGER ]: '/warehouse-manager-account-manager',
	[ HubProduct.ADMIN ]: '/admin',
	[ HubProduct.FULFILLMENT_MARKETPLACE ]: '/fulfillment-marketplace',
	[ HubProduct.ACCOUNTING_INTEGRATION ]: '/accounting',
	[ HubProduct.ANALYTICS ]: '/analytics'
}

export const reverseLookupRoutes: Record<string, HubProduct> = Object.entries(routes).reduce((acc, [ appName, appRoute ]) => ({
	...acc,
	[appRoute]: appName
}), {})
